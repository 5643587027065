export const EXPERIENCES = [
  {
    title: "Software Developer",
    company: "Electric Power Research Institute (EPRI)",
    location: "Palo Alto, CA",
    date: "Jun 2023 – Present",
    description: [
      ["Enhanced fuel analysis rate by 20% by developing desktop app for damage detection", []],
      [
        "Augmented fuel grid detection accuracy by 10% by training convolutional neural network",
        [],
      ],
      ["Delivered 10+ web and desktop apps while juggling 3+ projects", []],
    ],
    categories: ["Full-Stack", "Desktop", "Machine Learning"],
    languages: ["Python", "C#", "C++", "JavaScript", "TypeScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: [".NET", "Blazor", "WPF", "Angular", "Bootstrap", "Flask"],
    tools: ["Git", "Linux", "Docker", "SQL Server", "SQLite", "Azure"],
    machineLearning: [
      "PyTorch",
      "TensorFlow",
      "Keras",
      "scikit-learn",
      "Transformers",
      "NumPy",
      "pandas",
    ],
  },
  {
    title: "Associate Software Developer",
    company: "Electric Power Research Institute (EPRI)",
    location: "Palo Alto, CA",
    date: "Jul 2022 – Jun 2023",
    description: [
      [
        "Increased developer efficiency by 15% by implementing app and API authorization system",
        [],
      ],
      [
        "Raised authorization config speed by 10% by designing web app for administrator control",
        [],
      ],
      ["Worked full-time while attending full-time graduate program", []],
    ],
    categories: ["Full-Stack"],
    languages: ["C#", "JavaScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: [".NET", "Blazor", "Bootstrap"],
    tools: ["Git", "Docker", "SQL Server", "Azure"],
    machineLearning: [],
  },
  {
    title: "Student Software Developer",
    company: "Electric Power Research Institute (EPRI)",
    location: "Palo Alto, CA",
    date: "Feb 2021 – Jul 2022",
    description: [
      ["Improved SQA testing reliability by 15% by building web app for automated testing", []],
      ["Sped up containment inspection by 10% by creating web app for defect recognition", []],
      ["Worked full-time while attending full-time undergraduate program", []],
    ],
    categories: ["Full-Stack"],
    languages: ["Python", "C#", "JavaScript", "TypeScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: [".NET", "Blazor", "Angular", "Bootstrap", "Flask"],
    tools: ["Git", "Linux", "Docker", "SQL Server", "SQLite", "Azure"],
    machineLearning: ["NumPy", "pandas"],
  },
];
